import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';

class AuthorizedRoute extends Component {
  render() {
    const { component: Component, admin, ...rest } = this.props;

    return (
      <Route {...rest} render={props => {
        return admin.logged
          ? <Component {...props} />
          : <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
      }} />
    )
  }
}

const mapStateToProps = state => 
  ({
    admin: state.admin,
  });

export default connect(mapStateToProps)(AuthorizedRoute);