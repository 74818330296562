import {
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAIL, 
  UNAUTHENTICATED,
} from '../constants';

const initialState = {
  email: '',
  first_name: '',
  last_name: '',
  logged: false,
};

const admin = (state = initialState, action) => {
  switch (action.type) {

    case AUTHENTICATE_SUCCESS:
      return {
        logged: true,
        ...action.payload,
      };
      
    case AUTHENTICATE_FAIL:
      return initialState;

    case UNAUTHENTICATED:
      return initialState;

    default:
      return state;
  }

}

export default admin;
