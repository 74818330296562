import {
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAIL,
  UNAUTHENTICATED,
} from '../constants';

export const authenticateSuccess = payload => 
  ({
    type: AUTHENTICATE_SUCCESS,
    payload,
  });

export const authenticateFailed = () => 
  ({
    type: AUTHENTICATE_FAIL,
  });

export const unauthenticated = () => 
  ({
    type: UNAUTHENTICATED,
  });