import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Rest from '../../../tools/rest';
import Typography from '@material-ui/core/Typography';
import Preloader from '../../../components/bootstrap/preloader';
import {toast} from 'react-toastify';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import PaginationComponent from '../../../components/bootstrap/pagination';
import styles from './styles';
import moment from 'moment';

class UsersPage extends Component {
  state = {
    users: [],
    loaded: false,
    page: 0,
    perPage: 50,
    totalCount: 0,
  }

  componentDidMount() {
    this.loadingData();
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page || 
      prevState.perPage !== this.state.perPage
    ) {
      this.loadingData();
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ perPage: parseInt(event.target.value) });
  };

  loadingData = () => {
    const {page, perPage} = this.state;

    this.setState({loaded: false});
    Rest.get(`users.json?per=${perPage}&page=${page + 1}`)
      .then(json => {
        const result = json.data.data || {};
        const {users, meta: {page, per_page, total_count}} = result;

        this.setState({
          users,
          page: page - 1,
          perPage: per_page,
          totalCount: total_count,
          loaded: true,
        });
      })
      .catch(e => {
        toast.error(e)
        this.setState({loaded: true});
      });
  }
  
  render() {
    const { classes } = this.props;
    const {users, loaded, totalCount, perPage, page} = this.state;

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Users
        </Typography>
        <Paper className={classes.root}>
          <Preloader loaded={loaded}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Login</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(user => {
                  return (
                    <TableRow key={user.name}>
                      <TableCell>{user.display_name}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{moment(user.creation_ts * 1000).format('HH:mm DD.MM.YYYY')}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => this.props.history.push(`/users/${user.name}`)} aria-label="Edit" color="primary" className={classes.margin}>
                          <EditRoundedIcon/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    count={totalCount}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={PaginationComponent}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Preloader>
        </Paper>
      </React.Fragment>
      
    )
  }
}

UsersPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UsersPage);