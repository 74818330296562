import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PasswordForm from '../form/password';
import Paper from '@material-ui/core/Paper';
import styles from './styles';

class UserEditPage extends Component {
  render() {
    const {classes} = this.props;
    const userId = this.props.match.params.id;

    return (
        
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h5" gutterBottom component="h2">
            {`Reset Password`}
          </Typography>
          <PasswordForm userId={userId}/>
        </Paper>
      </div>
    )
  }
}

UserEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserEditPage);