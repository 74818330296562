import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Rest from '../../../../tools/rest';
import {toast} from 'react-toastify';
import Preloader from '../../../../components/bootstrap/preloader';
import styles from './styles';

class PasswordForm extends Component {
  
  state = {
    loaded: true,
    password: '',
    password_confirmation: '',
    validation: null,
  };

  handleSubmit = () => {
    const {password, password_confirmation} = this.state;

    this.setState({loaded: false});
    Rest.put(`users/${this.props.userId}/reset_password.json`, {
      user: {
        password,
        password_confirmation,
      }
    })
      .then(json => {
        const {validation} = json.data;

        if (validation) {
          this.setState({
            validation: validation.user,
            loaded: true,
          });
        } else {
          toast.success('Пароль успешно обновлен')
          this.setState({
            validation: null,
            password: '',
            password_confirmation: '',
            loaded: true,
          });
        }
      })
      .catch(e => {
        toast.error(e);
        this.setState({loaded: true});
      });
  }

  handleChange = event => {
    const {value, name} = event.target;
    this.setState({[name]: value});
  }

  render() {
    const {classes} = this.props;
    const {password, password_confirmation, loaded, validation} = this.state;

    return (
      <div className={classes.root}>
        <Preloader loaded={loaded}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <TextField
                required
                name="password"
                type="password"
                label="New password"
                fullWidth
                autoComplete="fname"
                variant="outlined"
                onChange={this.handleChange}
                value={password}
                error={validation && validation.password}
                helperText={validation && validation.password}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                type="password"
                name="password_confirmation"
                label="Confirm password"
                fullWidth
                variant="outlined"
                onChange={this.handleChange}
                value={password_confirmation}
                error={validation && validation.password_confirmation}
                helperText={validation && validation.password_confirmation}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {}}
                  className={classes.button}
                  onClick={this.handleSubmit}
                >
                  Reset
                </Button>
              </div>
            </Grid>
          </Grid>
        </Preloader>
      </div>
    )
  }
}

PasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PasswordForm);