import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {authenticateSuccess, authenticateFailed} from '../../redux/actions/admin';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Rest from '../../tools/rest';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import withStyles from '@material-ui/core/styles/withStyles';
import {toast} from 'react-toastify';
import Preloader from '../../components/bootstrap/preloader';

class LoginAction extends Component {
  state = {
    email: '',
    password: '',
    redirectToReferrer: false,
    loaded: true,
  }

  handleChange = event => {
    const {name, value} = event.target;
    this.setState({[name]: value});
  }

  handleSubmit = event => {
    event.preventDefault();

    const {email, password} = this.state;

    this.setState({loaded: false});
    Rest.post('sessions.json', {email, password})
      .then(json => {
        const {validation, data, error} = json.data;

        if (data) {
          this.props.authenticateSuccess(data.admin);
          this.setState({redirectToReferrer: true});
          return;
        }

        if (error) {
          toast.error(error);
          this.setState({loaded: true});
          return;
        }

        const {admin} = validation;
        toast.error(admin.base.join(', '));

        this.setState({loaded: true});
      })
      .catch(e => {
        toast.error(e)
        this.props.authenticateFailed();
        this.setState({loaded: true});
      });
  }

  render() {
    const {classes} = this.props;
    const {email, password, redirectToReferrer, loaded} = this.state;
    const {from} = this.props.location.state || {from: {pathname: "/"}};

    if (redirectToReferrer) return <Redirect to={from} />;

    return (
      <main className={classes.main}>
        <CssBaseline />
        
        <Paper className={classes.paper}>
          <Preloader loaded={loaded}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5">Авторизация</Typography>
            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Почта</InputLabel>
                <Input name="email" value={email} onChange={this.handleChange} autoFocus autoComplete=""/>
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Пароль</InputLabel>
                <Input name="password" value={password} type="password" onChange={this.handleChange}/>
              </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}
                  className={classes.submit}
                >
                  Войти
              </Button>
            </form>
          </Preloader>
        </Paper>
      </main>
    )
  }
}

LoginAction.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => 
  ({
    authenticateSuccess(admin) {
      dispatch(authenticateSuccess(admin))
    },
    authenticateFailed() {
      dispatch(authenticateFailed())
    },
  });

export default connect(null, mapDispatchToProps)(withStyles(styles)(LoginAction));