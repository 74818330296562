import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from '@material-ui/core/styles';
import Sidebar from './sidebar';
import Header from './header';
import styles from './styles';
import UsersPage from '../pages/users/all';
import UserEditPage from '../pages/users/edit';

class Layout extends Component {
  state = {
    open: false,
  }

  handleDrawerClose = () => {
    this.setState({open: false});
  }

  handleDrawerOpen = () => {
    this.setState({open: true});
  }

  render() {
    const {classes, match, history} = this.props;
    const {open} = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Header open={open} handleDrawerOpen={this.handleDrawerOpen}/>
        <Sidebar open={open} history={history} handleDrawerClose={this.handleDrawerClose}/>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Route path={`${match.url}users`} exact component={UsersPage} />
          <Route path={`${match.url}users/:id`} component={UserEditPage} />
          <Route path={`${match.url}`} exact component={UsersPage} />
        </main>
      </div>
    )
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Layout);