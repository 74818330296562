import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  xsrfCookieName: "CSRF-TOKEN",
  xsrfHeaderName: "X-CSRF-Token",
  withCredentials: true,
});

class Rest {
  constructor(params) {
    this.params = Object.assign(
			{
				url: '',
				method: 'GET',
				type: 'json',
        data: {},
			},
			params,
		);
  }

  static get(url, data = {}) {
    return new Rest({url, data});
  }

  static delete(url, data = {}) {
    return new Rest({method: 'DELETE', url, data});
  }

  static post(url, data = {}) {
		return new Rest({method: 'POST', url, data});
  }
  
  static put(url, data = {}) {
		return new Rest({method: 'PUT', url, data});
	}

  then(callback = json => {}){
    instance.request(this.params)
      .then(callback)
      .catch(error => {
				this.callCatch(error);
      });
    return this;
  }


  callCatch(json) {
    if (this.catchCallback) {
      this.catchCallback(json);
    }
  }

  catch(callback) {
    if (!this.catchResult) {
      this.catchCallback = callback;
    }
    return this;
  }
}

export default Rest;