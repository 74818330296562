import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {authenticateSuccess, unauthenticated} from './redux/actions/admin';
import Layout from './layout';
import LoginAction from './pages/login';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import {store, persistor} from './store';
import AuthorizedRoute from './routing/authorized_route';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Rest from './tools/rest';

class App extends Component {
  componentDidMount() {
    Rest.get('sessions/check.json')
      .then(json => {
        const {data} = json.data;

        if (data) {
          store.dispatch(authenticateSuccess(data.admin))
          return;
        } else {
          store.dispatch(unauthenticated());
        }
      })
      .catch(e => {
        store.dispatch(unauthenticated());
      });
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <React.Fragment>
              <Switch>
                <Route path="/login" component={LoginAction} />
                <AuthorizedRoute path="/" component={Layout}/>
              </Switch>
            </React.Fragment>
          </BrowserRouter>
          <ToastContainer 
            hideProgressBar={false}
            autoClose={2000}
          />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
