import React, {Component} from 'react';
import {connect} from 'react-redux';
import Rest from '../../tools/rest';
import {unauthenticated} from '../../redux/actions/admin';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import styles from './styles';
import {toast} from 'react-toastify';

class Header extends Component {
  logout = () => {
    Rest.delete('sessions.json')
      .then(_ => {
        this.props.unauthenticated();
      })
      .catch(e => {
        toast.error(e)
      });
  }

  render() {
    const {classes, open, handleDrawerOpen, admin} = this.props;

    return (
      <AppBar
        position="absolute"
        className={classNames(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar disableGutters={!open} className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={classNames(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Chat Admin
          </Typography>
          <Typography
            variant="caption"
            color="inherit"
            align="right"
            noWrap
            className={classes.title}
          >
            {`${admin.first_name} ${admin.last_name}`}
          </Typography>
          <IconButton color="inherit" onClick={this.logout}>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => 
  ({
    admin: state.admin,
  });

const mapDispatchToProps = dispatch => 
  ({
    unauthenticated() {
      dispatch(unauthenticated());
    },
  });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));