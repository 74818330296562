export default theme => ({
  root: {
    marginTop: theme.spacing.unit * 3
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing.unit,
  }
});